<template>
  <div class="member-invite-event-block side-highlight-container">
    <div class="flex items-center justify-between mb-[40px]">
      <ContainerTitle
        title="獎勵發放設定"
        hideTooltip
        description="如需變更設定，請至「事件行銷列表」編輯對應活動"
      />

      <a @click="eventMarketingListClick">
        <BaseElButton plain>
          前往事件行銷變更
        </BaseElButton>
      </a>
    </div>

    <div>
      <div v-for="eventActivity in displayData" :key="eventActivity.id">
        <p class="mb-[12px] font-medium">
          <a class="cursor-pointer" @click="eventMarketingClick(eventActivity)">{{ eventActivity.name }}</a>
          <span
            class="inline-display ml-[20px] font-normal"
            :class="{
              'text-primary-100': get(eventActivity.status, 'color') === 'primary',
              'text-gray-60': get(eventActivity.status, 'color') === 'gray',
            }"
          >
            {{ get(eventActivity.status, 'label') }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import ContainerTitle from '@/components/Title/ContainerTitle.vue'
import { get, map } from 'lodash'
import { usePermissions } from '@/use/permissions'
import { useRouter } from 'vue-router/composables'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'MemberInviteEventBlock',
  components: { ContainerTitle },
  props: {
    relatedEvent: { type: Array, default: () => [] },
  },
  setup (props) {
    const router = useRouter()
    const { checkAction } = usePermissions()
    const eventTemplatePermission = computed(() => checkAction('admin.eventTemplateConfig.page'))
    const displayData = computed(() => {
      const statusDict = {
        notYet: { label: '未開始', color: 'gray' },
        open: { label: '進行中', color: 'primary' },
        finished: { label: '已結束', color: 'gray' },
      }

      return map(props.relatedEvent, (i) => {
        return {
          id: i.id,
          name: i.name,
          status: statusDict[i.status],
        }
      })
    })
    const eventMarketingClick = (item) => {
      if (eventTemplatePermission.value) {
        router.push({ name: 'EventMarketingEdit', params: { id: item.id }, query: { back: true } })
      } else {
        window.$message.error(notifyMessage.permissionsDenied)
      }
    }
    const eventMarketingListClick = () => {
      if (eventTemplatePermission.value) {
        router.push({ name: 'EventMarketingList' })
      } else {
        window.$message.error(notifyMessage.permissionsDenied)
      }
    }
    return { displayData, get, eventMarketingClick, eventMarketingListClick }
  },
})
</script>

<style lang="postcss" scoped>

</style>
